import React, { useEffect, useState } from "react";
import TextInput from "../TextInput/TextInput"; // Assuming TextInput is a custom component
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/styles";

const AutoCompleteSelect = ({
  label,
  options = [],
  size,
  _key,
  getOptionLabel,
  getOptionSelected,
  loading,
  handleChange = () => {},
  selectedValue,
  error,
  customInput,
  ...props
}) => {
  const [value, setValue] = useState(null);
  const [customOption, setCustomOption] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Handle the change event and propagate it to the parent component
  const onChange = (e, newValue) => {
    setValue(newValue); // Set the selected value
    handleChange(newValue); // Pass the selected object (not just the key) to the parent
    setInputValue(""); // Clear input field
  };

  // Synchronize selectedValue with the component state
  useEffect(() => {
    if (
      selectedValue &&
      selectedValue instanceof Object &&
      Object.keys(selectedValue).length
    ) {
      setValue(selectedValue); // Set the selected value (if it's an object)
    } else {
      setInputValue(selectedValue || ""); // Set input value if selectedValue is just a string
    }
  }, [selectedValue, _key, options]);

  // Handle input changes and ensure custom input logic is applied
  const onInputChange = (e, newValue) => {
    // Ensure the input value is always a string
    const replacedChar = String(newValue.replace(/[^0-9]/g, ""));
    setInputValue(customInput ? replacedChar : newValue);

    // Handle custom input logic
    if (customInput) {
      if (
        replacedChar >= customInput.min &&
        replacedChar <= customInput.max &&
        !options.some((item) => String(item) === replacedChar)
      ) {
        setCustomOption([String(replacedChar)]); // Add custom option
      } else {
        setCustomOption([]); // Clear custom options if not valid
      }
    }
  };

  return (
    <CustomAutoComplete
      disablePortal
      disableClearable
      blurOnSelect
      options={customInput ? [...customOption, ...options] : options}
      onChange={onChange} // Handle selection change
      onInputChange={onInputChange} // Handle input change
      getOptionLabel={getOptionLabel} // Function to extract option labels
      getOptionSelected={getOptionSelected} // Function to compare selected options
      value={value || null} // Set the selected value (object from options)
      inputValue={inputValue || ""} // Ensure inputValue is a string
      {...props} // Spread remaining props (e.g., size, label)
      renderInput={(params) => (
        <TextInput
          {...params}
          {...props}
          error={error}
          value={inputValue || ""} // Ensure the input is always a string
          label={label}
          size={size}
        />
      )}
    />
  );
};

export default React.memo(AutoCompleteSelect);
const CustomAutoComplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    padding: 0,
    "& .MuiAutocomplete-input": {
      padding: "18.5px 14px !important",
    },
  },
});
