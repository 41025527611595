import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
import {
  goNextIndex,
  resetProposal,
  setInspectionInfo,
  setProposalData,
} from "../pages/Proposal/Proposal.slice";
export const useInitiateProposalMutation = (req) => {
  const { get, set, remove } = useUrlQuery();

  const quote_id = get("quote_id");
  const dispatch = useDispatch("");
  const navigate = useNavigate();
  const { isLoading, mutate } = useMutation(
    (req) =>
      api.post("api/InitiateCarProposal", { ...req, QUOTE_ID: quote_id }),
    {
      onSuccess: ({ data }) => {
        const proposal_id = data.response.PROPOSAL_ID;
        dispatch(resetProposal());
        toast.dismiss();
        remove("quote_id");
        navigate(`/proposal?${set("proposal_id", proposal_id)}`);
      },
    }
  );

  return { initiateProposal: mutate, isSubmitting: isLoading };
};
export const useProposalEnquiry = () => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["selected", proposal_id],
    () => api.post("api/GetProposalDetails", { PROPOSAL_ID: proposal_id }),
    {
      onSuccess: (data) => {
        dispatch(setProposalData(data?.data?.data?.PROPOSAL.FORMDATA || {}));
      },
    }
  );

  return {
    vehicle: data?.data?.data?.QUOTE_REQUEST,
    product: data?.data?.data?.product_data,
    proposal: data?.data?.data?.PROPOSAL,
    isLoading,
  };
};
export const useUpdateProposalMutation = (isFormData) => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const dispatch = useDispatch();

  const { isLoading, mutate, isSuccess, mutateAsync } = useMutation(
    (data) => {
      const formData = new FormData();
      const newData = { PROPOSAL_ID: proposal_id, ...data };
      Object.keys(newData).forEach((item) => {
        formData.append(item, newData[item]);
      });
      return api.post(
        "api/UpdateRawProposal",
        isFormData
          ? formData
          : {
              PROPOSAL_ID: proposal_id,
              FORMDATA: data,
            },
        {
          headers: {
            "Content-Type": isFormData
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
    },
    {
      onSuccess: () => {
        dispatch(goNextIndex());
        toast.success("Details Updated Successfully!");
      },
    }
  );
  return { submit: mutate, isLoading, isSuccess, asyncSubmit: mutateAsync };
};
export const useGetRelationMasterQuery = (company_slug) => {
  const { isLoading, data } = useQuery(["relation", company_slug], () => {
    if (company_slug)
      return api.post("api/GetRelationMaster", {
        LOB: "car",
        COMPANY_SLUG: company_slug,
      });
  });

  return {
    relations: data?.data?.response,
    isLoading,
  };
};
export const useGetOccupationMasterQuery = (company_slug) => {
  console.log(company_slug);
  const { isLoading, data } = useQuery(["occupation", company_slug], () => {
    if (company_slug)
      return api.post("api/GetOccupationMaster", {
        LOB: "car",
        COMPANY_SLUG: company_slug,
      });
  });

  return {
    occupations: data?.data?.response,
    isLoading,
  };
};
export const useGetPreviousInsurerMasterQuery = (company_slug) => {
  const { isLoading, data } = useQuery(
    ["previous_insurer", company_slug],
    () => {
      if (company_slug)
        return api.post("api/GetPreviousInsurerMaster", {
          LOB: "car",
          COMPANY_SLUG: company_slug,
        });
    }
  );
  console.log(data, "dataaaaaaaaaauseGetPreviousInsurerMasterQuery");

  return {
    previous_insurer: data?.data?.response,
    isLoading,
  };
};
export const useGetPincodeMutation = () => {
  const { isLoading, data, isError, mutate } = useMutation(
    async ({ company_alias, pincode }) => {
      return await api.post("api/GetPincodeMaster", {
        pincode,
        company_alias,
      });
    },
    {
      onError: () => {
        toast.error("Wrong Pincode!!");
      },
    }
  );
  return {
    isLoading,
    getCityState: mutate,
    city: data?.data?.response?.city,
    state: data?.data?.response?.state,
    isError,
  };
};
export const serialize = function (obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const useFinalSubmitMutation = () => {
  const { get, set } = useUrlQuery();
  const proposal_id = get("proposal_id");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { data, isLoading, mutate } = useMutation(
    async () =>
      api.post("/api/ProposalFinalSubmit", {
        PROPOSAL_ID: proposal_id,
      }),
    {
      onSuccess: ({ data }) => {
        if (data.status === 500) {
          toast.error(data.message, {
            position: "top-right",
            autoClose: false,
            closeOnClick: true,
          });
          navigate(`/proposal?${set("proposal_id", proposal_id)}`);
        } else {
          if (data?.BREAKIN_DATA) {
            dispatch(setInspectionInfo(data?.BREAKIN_DATA));
            return;
          }
          navigate(`/summary?${set("proposal_id", proposal_id)}`);
          // queryClient.invalidateQueries("selected");
        }
      },
      onError: ({ data }) => {
        toast.error(data.message, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
        });
      },
    }
  );

  return {
    finalSubmit: mutate,
    finalSubmitData: data?.data,
    isLoading,
  };
};
export const usePaymentQuery = () => {
  const { get, set } = useUrlQuery();
  const navigate = useNavigate();
  const proposal_id = get("proposal_id");
  const { isLoading, data } = useQuery(
    ["payment", proposal_id],
    () => {
      return api.post("api/GetPaymentDetails", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        console.log(data.data);
        if (data.data.status === 500 && !data.data.resubmit) {
          navigate(`/proposal?${set("proposal_id", proposal_id)}`);
        }
      },
    }
  );

  return {
    data: data?.data,
    isPaymentLoading: isLoading,
    isResubmit: data?.data?.resubmit,
  };
};
export const useFinancerMasterQuery = (company_slug) => {
  const { isLoading, data } = useQuery(["financer", company_slug], () => {
    if (company_slug)
      return api.post("api/GetFinancierMaster", {
        LOB: "car",
        COMPANY_SLUG: company_slug,
      });
  });

  return {
    financer: data?.data?.response,
    isLoading,
  };
};
export const useUpdateOrderMutation = () => {
  const { get } = useUrlQuery();
  const proposal_id = get("proposal_id");

  const { isLoading, mutate, isSuccess } = useMutation(() =>
    api.post("api/UpdateOrderStage", {
      PROPOSAL_ID: proposal_id,
      ORDER_STAGE: "PG REDIRECTED",
    })
  );
  return { updateOrder: mutate, isLoading, isSuccess };
};
export const useVerifyPanCKYC = () => {
  const queryClient = useQueryClient();
  const { product } = useProposalEnquiry();
  const { data, mutate, mutateAsync, isLoading, reset } = useMutation(
    (data) => {
      return api.post(
        product?.INSURANCE_SLUG === "reliance"
          ? "api/GetRelianceCKYCDetails"
          : "api/GetHdfcCKYCDetails",
        { ...data, LOB: "CAR" }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["selected"]);
      },
    }
  );

  return {
    verifyCKYC: mutateAsync,
    isVerifyCKYC: isLoading,
    resetVerify: reset,
  };
};
