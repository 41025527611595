import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useAddonsFromUrl } from "../../../../../../hooks/addonsFromUrl";
import useUrlQuery from "../../../../../../hooks/useUrlQuery";
import { useEnquiryQuery } from "../../../../../../services/CarServices";
import { useInitiateProposalMutation } from "../../../../../../services/ProposalServices";
import { setShowPolicyDetails } from "../../../../QuotesPage.slice";
import EditPolicyDetails from "../../../EditPolicyDetails/EditPolicyDetails";
import {
  AdditionalDetails,
  DetailsLabel,
  DetailsWrapper,
  EditButton,
  InnerWrapper,
} from "../../CarAndPolicy.style";
import "styled-components/macro";
import moment from "moment";
const PolicyDetails = () => {
  const { showPolicyDetails } = useSelector((state) => state.quotes);

  const { get, set } = useUrlQuery();
  const stage = get("stage");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClose = () => {
    if (stage !== "PF") {
      navigate(`/quotes?${set("stage", "PF")}`, {
        replace: true,
      });
    }
    dispatch(setShowPolicyDetails(false));
  };
  const { requestData, responseData } = useEnquiryQuery();
  console.log( requestData,"responseDataresponseDataresponseDataresponseData");
  return (
    <>
      <DetailsWrapper>
        <InnerWrapper>
          <DetailsLabel>
            Existing Policy Details
            <AdditionalDetails nopad>
            {requestData?.registration_date && moment(requestData.registration_date).year() !== moment().year() && (
              <li>Policy Expiry Date: {requestData?.existing_exp_date}</li>
            )}
              <li>
                Policy type:{" "}
                {requestData?.prev_policy_type === "TP"
                  ? "Third Party"
                  : "Comprehensive"}
              </li>
            </AdditionalDetails>
          </DetailsLabel>
        </InnerWrapper>
        <EditButton onClick={() => dispatch(setShowPolicyDetails(true))}>
          Edit
        </EditButton>
        <EditPolicyDetails open={showPolicyDetails} onClose={onClose} />
      </DetailsWrapper>
    </>
  );
};

export default PolicyDetails;
