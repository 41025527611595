import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ProposalFormWrapper } from "../../ProposalForm.style";
import CarOwnerDetails from "./components/CarOwnerDetails/CarOwnerDetails";
import CommunicationAddress from "./components/CommunicationAddress/CommunicationAddress";
import PersonalDetails from "./components/PersonalDetails/PersonalDetails";
import VehicleDetails from "./components/VehicleDetails/VehicleDetails";
import DigitCKYCDetails from "./components/CKYCDetails/DigitCKYCDetails";
import { useFinalSubmitMutation, useProposalEnquiry } from "../../../../services/ProposalServices";
import HeartLoader from "../../../../components/Loader/Loader"


const ProposalForm = () => {
  const { activeIndex, proposalData } = useSelector((state) => state.proposal);
  const { product } = useProposalEnquiry();
  const { isLoading } = useFinalSubmitMutation();
  console.log(isLoading,"isLoadingisLoading")

  return (
    <ProposalFormWrapper>
      <CarOwnerDetails />
      {proposalData.owner_type !== "COMP" && <PersonalDetails />}
      <CommunicationAddress />
      <VehicleDetails />
      {(product?.INSURANCE_SLUG === "godigit" || product?.INSURANCE_SLUG === "icici_lombard_general") && isLoading ? (
          <HeartLoader /> // Assuming you want a loading spinner here
        ) : (
          <DigitCKYCDetails />
        )}
    </ProposalFormWrapper>
  );
};

export default ProposalForm;
