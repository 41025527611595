import React from "react";
import { useGetQuotesQuery,useGetAddonsQuery, } from "../../../../../../services/QuoteServices";
import {
  AddonDetail,
  AddonLabel,
  BuyNowButton,
  Label,
  LogoWrapper,
  MobileQuoteCard,
  PremiumWrapper,
  QuoteCardWrapper,
  QuoteDataWrapper,
  QuoteDetails,
  Value,
} from "./QuoteCard.style";
import "styled-components/macro";
import { Skeleton } from "@material-ui/lab";
import logo from "./../../../../../../assets/images/edelweiss.png";
import { useSelector, useDispatch } from "react-redux";
import {
  addOnsSlugSelector,
  planTypeSelector,
  selectedAddonsSelector,
  setSelectedQuotesDetails,
  setShowPaDialog,
  setShowPolicyDetails,
} from "../../../../QuotesPage.slice";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../../../../hooks/useUrlQuery";
import { useInitiateProposalMutation } from "../../../../../../services/ProposalServices";
import PolicyDetails from "../../../PolicyDetails/PolicyDetails";
import Addons from "../../../Addons/Addons";
// import { useAddonsFromUrl } from "../../../../../../hooks/addonsFromUrl";
const getAddOnPremium = (addOns = []) => {
  return addOns.reduce((prev, cur) => prev + parseInt(cur.premium), 0);
};
const QuoteCard = ({ endPoint }) => {
  const {
    data,
    isLoading,
    isError,
    premium_data,
    included_addons,
    available_addons,
    product_data,
  } = useGetQuotesQuery(endPoint);
  const selected_addons = useSelector(selectedAddonsSelector);
  const addons_name_slug = useSelector(addOnsSlugSelector);
  console.log(addons_name_slug,"addons_name_slug")
  console.log(available_addons,"available_addons")
  console.log(data,"datatatatatatatat")
  const { quotes } = useSelector((state) => state.quotes);
  const [showPlanDetails, setShowPlanDetails] = useState(false);


  const [opted, setOpted] = useState([]);
  const additional_premium = getAddOnPremium(opted);
  const planType = useSelector(planTypeSelector);
  const showThisPlan =
    planType.code === "ALL" ||
    isLoading ||
    planType.code === product_data?.POLICY_TYPE;
 
      const all_add_ons = [
        ...Object.keys(available_addons || {}).filter(
          (item) =>
            selected_addons.includes(item) &&
            available_addons[item] !== "NOT FOUND" &&
            available_addons[item] !== "Not Found"
        ),
    ...Object.keys(included_addons).filter(
      (item) => included_addons[item] !== "NOT FOUND"
    ),
  ];
  const included = Object.keys(included_addons)
    .filter((item) => included_addons[item] !== "NOT FOUND")
    .map((item) => ({
      slug: item,
      premium: included_addons[item],
      name: addons_name_slug[item],
    }));

  const order = quotes.findIndex((item) => {
    return item.PRODUCT_DATA.PRODUCTID === product_data?.PRODUCTID;
  });
  const onClose = () => {
    setShowPlanDetails(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    let added = [];
    Object.keys(available_addons || {}).forEach((item) => {
      if (
        selected_addons.includes(item) &&
        available_addons[item] !== "NOT FOUND" &&
        available_addons[item] !== "Not Found"
      ) {
        added = [
          ...added,
          {
            slug: item,
            premium: available_addons[item],
            name: addons_name_slug[item],
          },
        ];
      }
      console.log("available_addons", available_addons);
    });
    setOpted(added);
  }, [selected_addons, addons_name_slug, isLoading]);
  const isOD = product_data?.POLICY_TYPE === "OD";
  const navigate = useNavigate();
  const { set, get } = useUrlQuery();
  const stage = get("stage");

  const { initiateProposal, isSubmitting } = useInitiateProposalMutation();
  if (isError || !showThisPlan) return <></>;

  return (
    <>
      <QuoteCardWrapper flexOrder={order >= 0 ? order : 99}>
        <div className='plan-type'>{product_data?.PRODUCT_NAME}</div>
        <QuoteDetails>
          <LogoWrapper>
            {isLoading ? (
              <Skeleton variant={"rect"} width='160px' height='50px'></Skeleton>
            ) : (
              <img src={product_data?.LOGO_PATH} alt='logo'></img>
            )}
          </LogoWrapper>
          <QuoteDataWrapper>
            <Label></Label>
            <Value>
              {isLoading ? (
                <Skeleton variant='text' width={"80%"}></Skeleton>
              ) : (
                <>₹ {parseInt(premium_data?.IDV).toLocaleString("en-IN")}</>
              )}
            </Value>
          </QuoteDataWrapper>
          <QuoteDataWrapper>
            <Label>Add Ons:</Label>
            <Value>
              {isLoading ? (
                <Skeleton variant='text' width={"80%"}></Skeleton>
              ) : (
                all_add_ons.length
              )}
            </Value>
          </QuoteDataWrapper>
          <PremiumWrapper>
            <BuyNowButton
              disabled={isLoading}
              onClick={() => {
                if (stage !== "PF") {
                  navigate(`/quotes?${set("stage", "PF")}`, {
                    replace: true,
                  });
                  dispatch(setShowPolicyDetails(true));
                  return;
                }

                const isPaOpted = opted.some(
                  (item) => item.slug === "PA_COVER"
                );
                if (isPaOpted || isOD)
                  initiateProposal({
                    PRODUCT_ID: product_data?.PRODUCTID,
                    COMPANY_SLUG: product_data?.INSURANCE_SLUG,
                    IDV: premium_data?.IDV,
                    TOTAL_PREMIUM:
                      Number(premium_data?.TOTAL_PREMIUM) + additional_premium,
                    ADDONS: [...opted, ...included],
                    BASIC_OD: premium_data?.BASIC_OD,
                    BASIC_TP: premium_data?.BASIC_TP,
                    BIFUEL_OD: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                    BIFUEL_TP: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_TP,
                  });
                else {
                  dispatch(setShowPaDialog(true));
                  dispatch(
                    setSelectedQuotesDetails({
                      PRODUCT_ID: product_data?.PRODUCTID,
                      COMPANY_SLUG: product_data?.INSURANCE_SLUG,
                      IDV: premium_data?.IDV,
                      TOTAL_PREMIUM:
                        Number(premium_data?.TOTAL_PREMIUM) +
                        additional_premium,
                      ADDONS: [...opted, ...included],
                      BASIC_OD: premium_data?.BASIC_OD,
                      BASIC_TP: premium_data?.BASIC_TP,
                      BIFUEL_OD: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                      BIFUEL_TP: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_TP,
                    })
                  );
                }
              }}
            >
              <div className='label'>Buy Now</div>{" "}
              <div className='price'>
                {isLoading ? (
                  <CircularProgress
                    size={"20px"}
                    style={{ color: "var(--secondary-color)" }}
                  />
                ) : (
                  <>
                    {" "}
                    ₹{" "}
                    {(
                      parseInt(premium_data?.TOTAL_PREMIUM) + additional_premium
                    ).toLocaleString("en-IN")}
                  </>
                )}
              </div>
            </BuyNowButton>
            <button
              disabled={isLoading}
              className='view_plan'
              onClick={() => {
                setShowPlanDetails(true);
              }}
            >
              View Plan Details
            </button>
          </PremiumWrapper>
        </QuoteDetails>
        <AddonDetail className='addon_details'>
        {Array.from(new Set(all_add_ons)).map((item) => (
            <AddonLabel>
              <div className='name'>{addons_name_slug[item] + " "}:</div>
              <div className='premium'>
                {available_addons[item] ? `₹${available_addons[item]}` : "-"}
              </div>
            </AddonLabel>
          ))}
        </AddonDetail>
        <MobileQuoteCard>
          <div
            css={`
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
              align-items: center;
              & img {
                width: auto;
                height: 40px;
                max-width: 100px;
              }
            `}
          >
            {isLoading ? (
              <Skeleton variant={"rect"} width='100px' height='50px'></Skeleton>
            ) : (
              <img src={product_data?.LOGO_PATH} alt='logo'></img>
            )}
            <div
              css={`
                display: flex;
                flex-direction: column;
                & .view_plan {
                  background: #fff;
                  border: none;
                  font-size: 12px;
                  margin-top: 8px;
                  font-weight: 600;
                  color: var(--primary-color);
                }
              `}
            >
              <BuyNowButton
                disabled={isLoading}
                onClick={() => {
                  if (stage !== "PF") {
                    navigate(`/quotes?${set("stage", "PF")}`, {
                      replace: true,
                    });
                    dispatch(setShowPolicyDetails(true));
                    return;
                  }

                  const isPaOpted = opted.some(
                    (item) => item.slug === "PA_COVER"
                  );
                  if (isPaOpted || isOD)
                    initiateProposal({
                      PRODUCT_ID: product_data?.PRODUCTID,
                      COMPANY_SLUG: product_data?.INSURANCE_SLUG,
                      IDV: premium_data?.IDV,
                      TOTAL_PREMIUM:
                        Number(premium_data?.TOTAL_PREMIUM) +
                        additional_premium,
                      ADDONS: [...opted, ...included],
                      BASIC_OD: premium_data?.BASIC_OD,
                      BASIC_TP: premium_data?.BASIC_TP,
                      BIFUEL_OD: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                      BIFUEL_TP: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_TP,
                    });
                  else {
                    dispatch(setShowPaDialog(true));
                    dispatch(
                      setSelectedQuotesDetails({
                        PRODUCT_ID: product_data?.PRODUCTID,
                        COMPANY_SLUG: product_data?.INSURANCE_SLUG,
                        IDV: premium_data?.IDV,
                        TOTAL_PREMIUM:
                          Number(premium_data?.TOTAL_PREMIUM) +
                          additional_premium,
                        ADDONS: [...opted, ...included],
                        BASIC_OD: premium_data?.BASIC_OD,
                        BASIC_TP: premium_data?.BASIC_TP,
                        BIFUEL_OD: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_OD,
                        BIFUEL_TP: premium_data?.ADDONS?.CAN_OPT?.BIFUEL_TP,
                      })
                    );
                  }
                }}
              >
                <div className='label'>Buy Now</div>{" "}
                <div className='price'>
                  {isLoading ? (
                    <CircularProgress
                      size={"20px"}
                      style={{ color: "var(--secondary-color)" }}
                    />
                  ) : (
                    <>
                      {" "}
                      ₹{" "}
                      {(
                        parseInt(premium_data?.TOTAL_PREMIUM) +
                        additional_premium
                      ).toLocaleString("en-IN")}
                    </>
                  )}
                </div>
              </BuyNowButton>
              <button
                disabled={isLoading}
                className='view_plan'
                onClick={() => {
                  setShowPlanDetails(true);
                }}
              >
                View Plan Details
              </button>
            </div>
          </div>

          <div
            css={`
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <span
              css={`
                font-size: 14px;
                color: var(--dark-grey-1);
              `}
            >
              Cover Value(IDV) :
            </span>
            <span
              css={`
                font-weight: 800;
              `}
            >
              {isLoading ? (
                <Skeleton variant='text' width={"100px"}></Skeleton>
              ) : (
                <>₹ {parseInt(premium_data?.IDV).toLocaleString("en-IN")}</>
              )}
            </span>
          </div>
          {all_add_ons.length ? (
            <>
              {" "}
              <span
                css={`
                  font-size: 14px;
                  color: var(--dark-grey-1);
                  margin-top: 16px;
                  margin-bottom: 12px;
                `}
              >
                Addons :
              </span>
              {all_add_ons.map((item) => (
                <AddonLabel>
                  <div className='name'>{addons_name_slug[item] + " "}:</div>
                  <div className='premium'>
                    {available_addons[item]
                      ? `₹${available_addons[item]}`
                      : "-"}
                  </div>
                </AddonLabel>
              ))}
            </>
          ) : (
            <></>
          )}
        </MobileQuoteCard>
      </QuoteCardWrapper>
      <PolicyDetails
        open={showPlanDetails}
        onClose={onClose}
        premiumData={premium_data}
        productData={product_data}
        addons={opted}
        additional_premium={additional_premium}
        included={included}
      />
    </>
  );
};

export default QuoteCard;
