import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";

export const useVerifyQuery = () => {
  const { get, remove } = useUrlQuery();
  const hash = get("hash") || localStorage.getItem("hash");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      navigate({
        pathname: location.pathname,
        search: remove("hash"),
      });
    }
  }, [location.pathname]);
  const { data, isLoading } = useQuery(
    ["auth", hash, location.pathname],
    () => api.post("api/VerifyToken", { HASH: hash }),
    {
      onSuccess: (data) => {
        if (data?.data?.status === 200) localStorage.setItem("hash", hash);
        else {
          localStorage.removeItem("hash");
          // window.open("https://account.vkover.com", "_self");
        }
      },
      enabled:
        location.pathname === "/" || Boolean(localStorage.getItem("hash")),
    }
  );

  return {
    isVerified: data?.data?.status === 200,
    isVerifying: isLoading,
    user: data?.data?.response,
  };
};
