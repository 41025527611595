import React, { useEffect, useState } from "react";
import DateComp from "@mui/lab/DatePicker";
import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import TextInput from "../TextInput/TextInput";
import moment from "moment";
const DatePicker = ({
  value,
  handleChange = () => {},
  label,
  size,
  inputFormat = "DD/MM/YYYY",
  outputFormat = "YYYY-MM-DD",
  error,
  openTo = "year",
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    setSelectedDate(value || null);
  }, [value]);
  const [open, setOpen] = useState(false);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateComp
          open={props?.readOnly ? false : open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={selectedDate || null}
          onChange={(newDate) => {
            setSelectedDate(newDate);
            handleChange(newDate?.format(outputFormat));
          }}
          onAccept={(newDate) => {
            setSelectedDate(newDate);
            handleChange(newDate?.format(outputFormat));
          }}
          views={props.views || ["year", "month", "day"]}
          openTo={openTo}
          inputFormat={inputFormat}
          {...props}
          label={label}
          renderInput={(params) => (
            <TextInput
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: props?.readOnly,
              }}
              error={error}
              size={size || "medium"}
              onClick={() => {
                setOpen(true);
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePicker;
